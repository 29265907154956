import { createSlice } from '@reduxjs/toolkit';
import { fmProjectsAction } from './fmProjects.actions';

interface FMProjectsState {
  loading: boolean;
  success: any;
  error: any;
  projectsListData: any;
  projectListFiltersData: any;
  activePagination: any;
  projectsListError: any;
  projectDetails: any;
  projectDetailsError: any;

  expenseDetails: any;
  expenseUploadLoader: boolean;
  expenseUploadingFile: any;
  unverifiedExpenses: any;

  assignedProjects: any;
  projectInvoicesList: any;
  projectInvoiceDetails: any;
  releaseProjectInvoiceData: any;
  projectOverviewData: any;
  projectTimelineList: any;
  updateTaskConnections: any;
  projectPhaseTasksList: any;
  projectOverviewTaskData: any;
  projectPhases: any;
  projectOverviewAddTask: any;
  projectOverviewUpdateTask: any;
  projectOverviewDeleteTask: any;
  projectChangeOrderList: any;
  projectChangeOrderItems: any;
  createChangeOrder: any,
  changeOrderDetails: any,
  changeOrderTerms: any,
  savedChangeOrderNotes: any,
  projectExpensesList: any,
  changeOrderAttachmentList: any,
  changeOrderUploadSuccess: boolean,
  changeOrderRemoveSuccess: boolean,
  changeOrderRelease: boolean,
  changeOrderDelete: any,
  changeOrderDuplicate: any,
  coPaymentSchedule: any,
  rfpList: any
}
const initialState: FMProjectsState = {
  loading: false,
  success: null,
  error: null,
  projectsListData: [],
  projectListFiltersData: null,
  activePagination: {},
  projectsListError: "",
  projectDetails: null,
  projectDetailsError: "",

  expenseDetails: null,
  expenseUploadLoader: false,
  expenseUploadingFile: null,
  unverifiedExpenses: [],

  assignedProjects: [],
  projectInvoicesList: [],
  projectInvoiceDetails: null,
  releaseProjectInvoiceData: null,
  projectOverviewData: null,
  projectTimelineList: [],
  updateTaskConnections: null,
  projectPhaseTasksList: [],
  projectOverviewTaskData: null,
  projectPhases: [],
  projectOverviewAddTask: null,
  projectOverviewUpdateTask: null,
  projectOverviewDeleteTask: null,
  projectChangeOrderList: [],
  projectChangeOrderItems: [],
  createChangeOrder: null,
  changeOrderDetails: null,
  changeOrderTerms: null,
  savedChangeOrderNotes: null,
  projectExpensesList: [],
  changeOrderAttachmentList: null,
  changeOrderUploadSuccess: false,
  changeOrderRemoveSuccess: false,
  changeOrderRelease: false,
  changeOrderDelete: null,
  changeOrderDuplicate: null,
  coPaymentSchedule: [],
  rfpList: null
};

const fmProjectsSlice = createSlice({
  name: "fmProjectsSlice",
  initialState,
  reducers: {
    cleanChangeOrder: (state) => {
      state.createChangeOrder = [];
    },
    cleanProjectDetails: (state) => {
      state.projectDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // -------- cases of project manager assigned projects list api data -------
      .addCase(fmProjectsAction.getFinanceProjectsListAction.pending, (state, action) => {
        state.projectsListError = "";
        state.projectsListData = [];
        state.activePagination = {};
        state.projectListFiltersData = action?.meta?.arg;
      })
      .addCase(fmProjectsAction.getFinanceProjectsListAction.fulfilled, (state, action) => {
            if (action.payload.data.data) {
              state.projectsListData= action.payload.data.data;
              state.projectsListError = "";
              state.activePagination = action.payload.data.pagination;
              state.projectDetailsError = "";
            } else {
              state.error = "Unable to fetch projects list";
              state.activePagination = {}
            }
      })
      .addCase(fmProjectsAction.getFinanceProjectsListAction.rejected, (state, action:any) => {
            state.projectsListData = [];
            state.projectsListError = action?.error?.message || "Unable to fetch projects list";
            state.activePagination = {}
      })

      // -------- cases of project center invoices list api data -------
      .addCase(fmProjectsAction.getProjectInvoicesListAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.projectInvoicesList = [];
      })
      .addCase(fmProjectsAction.getProjectInvoicesListAction.fulfilled, (state, action) => {
            state.loading = false;
            if(action?.payload?.data?.status===200){
              state.error = null;
              state.success = action?.payload?.data?.message;
              state.projectInvoicesList = action?.payload?.data?.data;
            }else{
              state.success = null;
              state.projectInvoicesList = [];
              state.error = 'Projects invoices could not be fetched.';
            }
      })
      .addCase(fmProjectsAction.getProjectInvoicesListAction.rejected, (state, action:any) => {
            state.loading = false;
            state.success = null;
            state.projectInvoicesList = [];
            state.error = action?.payload?.data?.message ?? 'Projects invoices could not be fetched.';
      })

      // -------- cases of project center invoice details api data -------
      .addCase(fmProjectsAction.getProjectInvoiceDetailsAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.projectInvoiceDetails = null;
      })
      .addCase(fmProjectsAction.getProjectInvoiceDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.projectInvoiceDetails = action?.payload?.data?.data;
        }else{
          state.success = null;
          state.projectInvoiceDetails = null;
          state.error = 'Projects invoice details could not be fetched.';
        }
      })
      .addCase(fmProjectsAction.getProjectInvoiceDetailsAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.projectInvoiceDetails = null;
        state.error = action?.payload?.data?.message ?? 'Projects invoice details could not be fetched.';
      })

      // -------- cases of project center invoice update api data -------
      .addCase(fmProjectsAction.updateInvoiceAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(fmProjectsAction.updateInvoiceAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.projectInvoicesList = action?.payload?.data?.data;
        }else{
          state.success = null;
          state.error = 'Projects invoice could not be updated.';
        }
      })
      .addCase(fmProjectsAction.updateInvoiceAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.releaseProjectInvoiceData = null;
        state.error = action?.payload?.data?.message ?? 'Projects invoice could not be updated.';
      })
      
      // -------- cases of project center invoice release api data -------
      .addCase(fmProjectsAction.releaseInvoicesAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.releaseProjectInvoiceData = null;
      })
      .addCase(fmProjectsAction.releaseInvoicesAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.releaseProjectInvoiceData = action?.payload?.data?.data;
        }else{
          state.success = null;
          state.releaseProjectInvoiceData = null;
          state.error = 'Projects invoice could not be released.';
        }
      })
      .addCase(fmProjectsAction.releaseInvoicesAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.releaseProjectInvoiceData = null;
        state.error = action?.payload?.data?.message ?? 'Projects invoice could not be released.';
      })

      // -------- cases of project overview details api data -------
      .addCase(fmProjectsAction.getProjectOverviewDetailsAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        // set this as null only when user is not fetching this data while updating project tasks
        if(!action?.meta?.arg?.hideLoader){
          state.projectOverviewData = null;
        }
      })
      .addCase(fmProjectsAction.getProjectOverviewDetailsAction.fulfilled, (state, action) => {
            state.loading = false;
            if(action?.payload?.data?.status===200){
              state.error = null;
              state.success = action?.payload?.data?.message;
              state.projectOverviewData = action?.payload?.data?.data;
            }else{
              state.success = null;
              state.projectOverviewData = null;
              state.error = 'Projects overview details could not be fetched.';
            }
      })
      .addCase(fmProjectsAction.getProjectOverviewDetailsAction.rejected, (state, action:any) => {
            state.loading = false;
            state.success = null;
            state.projectOverviewData = null;
            state.error = action?.payload?.data?.message ?? 'Projects overview details could not be fetched.';
      })

      // -------- cases of project timeline list api data -------
      .addCase(fmProjectsAction.getProjectTimelineListAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        // set this as null only when user is not fetching this data while updating project tasks
        if(!action?.meta?.arg?.hideLoader){
          state.projectTimelineList = [];
        }
      })
      .addCase(fmProjectsAction.getProjectTimelineListAction.fulfilled, (state, action) => {
            state.loading = false;
            if(action?.payload?.data?.status===200){
              state.error = null;
              state.success = action?.payload?.data?.message;
              state.projectTimelineList = action?.payload?.data?.data?.map((item:any, index:number)=>{
                return {
                  ...item,
                  menuPosition: action?.payload?.data?.data?.length<4 || action?.payload?.data?.data?.length-index>3?true:false,
                }
              });
            }else{
              state.success = null;
              state.projectTimelineList = [];
              state.error = 'Projects timeline list could not be fetched.';
            }
      })
      .addCase(fmProjectsAction.getProjectTimelineListAction.rejected, (state, action:any) => {
            state.loading = false;
            state.success = null;
            state.projectTimelineList = [];
            state.error = action?.payload?.data?.message ?? 'Projects timeline list could not be fetched.';
      })

      // -------- cases of task connections updated api data -------
      .addCase(fmProjectsAction.updateTaskConnectionAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(fmProjectsAction.updateTaskConnectionAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.error = null;
          state.success = action?.payload?.data?.message;
          state.updateTaskConnections = action?.payload?.data?.data;
        }else{
          state.success = null;
          state.updateTaskConnections = null;
          state.error = 'Task connections could not be updated.';
        }
      })
      .addCase(fmProjectsAction.updateTaskConnectionAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.updateTaskConnections = null;
        state.error = action?.payload?.data?.message ?? 'Task connections could not be updated.';
      })

      // -------- cases of project phase tasks list api data -------
      .addCase(fmProjectsAction.getProjectTasksListAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        // set this as null only when user is not fetching this data while updating project tasks
        if(!action?.meta?.arg?.hideLoader){
          state.projectPhaseTasksList = [];
        }
      })
      .addCase(fmProjectsAction.getProjectTasksListAction.fulfilled, (state, action) => {
            state.loading = false;
            if(action?.payload?.data?.status===200){
              state.error = null;
              state.success = action?.payload?.data?.message;
              state.projectPhaseTasksList = action?.payload?.data?.data?.map((task:any) => {
                return {
                  ...task,
                  label: task?.name
                }
              });
            }else{
              state.success = null;
              state.projectPhaseTasksList = [];
              state.error = 'Projects phase-wise tasks list could not be fetched.';
            }
      })
      .addCase(fmProjectsAction.getProjectTasksListAction.rejected, (state, action:any) => {
            state.loading = false;
            state.success = null;
            state.projectPhaseTasksList = [];
            state.error = action?.payload?.data?.message ?? 'Projects phase-wise tasks list could not be fetched.';
      })

      // -------- cases of project overview task details api data -------
      .addCase(fmProjectsAction.getProjectOverviewTaskDetailsAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        if(!action?.meta?.arg?.hideLoader){
          state.projectOverviewTaskData = null;
        }
      })
      .addCase(fmProjectsAction.getProjectOverviewTaskDetailsAction.fulfilled, (state, action) => {
            state.loading = false;
            if(action?.payload?.data?.status===200){
              state.error = null;
              state.success = action?.payload?.data?.message;
              state.projectOverviewTaskData = action?.payload?.data?.data;
            }else{
              state.success = null;
              state.projectOverviewTaskData = null;
              state.error = 'Projects task details could not be fetched.';
            }
      })
      .addCase(fmProjectsAction.getProjectOverviewTaskDetailsAction.rejected, (state, action:any) => {
            state.loading = false;
            state.success = null;
            state.projectOverviewTaskData = null;
            state.error = action?.payload?.data?.message ?? 'Projects task details could not be fetched.';
      })

      // -------- cases of project phases api data -------
      .addCase(fmProjectsAction.getProjectPhasesAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.projectPhases = [];
      })
      .addCase(fmProjectsAction.getProjectPhasesAction.fulfilled, (state, action) => {
            state.loading = false;
            if(action?.payload?.data?.status===200){
              state.error = null;
              state.success = action?.payload?.data?.message;
              state.projectPhases = action?.payload?.data?.data;
            }else{
              state.success = null;
              state.projectPhases = [];
              state.error = 'Projects phases could not be fetched.';
            }
      })
      .addCase(fmProjectsAction.getProjectPhasesAction.rejected, (state, action:any) => {
            state.loading = false;
            state.success = null;
            state.projectPhases = [];
            state.error = action?.payload?.data?.message ?? 'Projects phases could not be fetched.';
      })

      // -------- cases of add project task api data -------
      .addCase(fmProjectsAction.addProjectTaskAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.projectOverviewAddTask = null;
      })
      .addCase(fmProjectsAction.addProjectTaskAction.fulfilled, (state, action) => {
            state.loading = false;
            if(action?.payload?.data?.status===200){
              state.error = null;
              state.success = action?.payload?.data?.message;
              state.projectOverviewAddTask = action?.payload?.data?.data;
            }else{
              state.success = null;
              state.projectOverviewAddTask = null;
              state.error = 'Projects task could not be added.';
            }
      })
      .addCase(fmProjectsAction.addProjectTaskAction.rejected, (state, action:any) => {
            state.loading = false;
            state.success = null;
            state.projectOverviewAddTask = null;
            state.error = action?.payload?.data?.message ?? 'Projects task could not be added.';
      })

      // -------- cases of update project task api data -------
      .addCase(fmProjectsAction.updateProjectTaskAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.projectOverviewUpdateTask = null;
      })
      .addCase(fmProjectsAction.updateProjectTaskAction.fulfilled, (state, action) => {
            state.loading = false;
            if(action?.payload?.data?.status===200){
              state.error = null;
              state.success = action?.payload?.data?.message;
              state.projectOverviewUpdateTask = action?.payload?.data?.data;
            }else{
              state.success = null;
              state.projectOverviewUpdateTask = null;
              state.error = 'Projects task could not be updated.';
            }
      })
      .addCase(fmProjectsAction.updateProjectTaskAction.rejected, (state, action:any) => {
            state.loading = false;
            state.success = null;
            state.projectOverviewUpdateTask = null;
            state.error = action?.payload?.data?.message ?? 'Projects task could not be updated.';
      })

      // -------- cases of delete project task api data -------
      .addCase(fmProjectsAction.deleteProjectTaskAction.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.projectOverviewDeleteTask = null;
      })
      .addCase(fmProjectsAction.deleteProjectTaskAction.fulfilled, (state, action) => {
            state.loading = false;
            if(action?.payload?.data?.status===200){
              state.error = null;
              state.success = action?.payload?.data?.message;
              state.projectOverviewDeleteTask = action?.payload?.data?.data;
            }else{
              state.success = null;
              state.projectOverviewDeleteTask = null;
              state.error = 'Projects task could not be deleted.';
            }
      })
      .addCase(fmProjectsAction.deleteProjectTaskAction.rejected, (state, action:any) => {
            state.loading = false;
            state.success = null;
            state.projectOverviewDeleteTask = null;
            state.error = action?.payload?.data?.message ?? 'Projects task could not be deleted.';
      })

      // -------- get Change Order -------
      .addCase(fmProjectsAction.getChangeOrderAction.pending, (state, action) => {
        state.error = "";
        state.projectChangeOrderList = [];
      })
      .addCase(fmProjectsAction.getChangeOrderAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.projectChangeOrderList= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to fetch change orders.";
        }
      })
      .addCase(fmProjectsAction.getChangeOrderAction.rejected, (state, action:any) => {
        state.projectChangeOrderList = [];
        state.error = action?.payload?.data?.message || "Failed to fetch change orders.";
      })

      // -------- get Change Order items -------
      .addCase(fmProjectsAction.getChangeOrderItemsAction.pending, (state, action) => {
        state.error = "";
        state.projectChangeOrderItems = [];
      })
      .addCase(fmProjectsAction.getChangeOrderItemsAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.projectChangeOrderItems= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to fetch change orders items.";
        }
      })
      .addCase(fmProjectsAction.getChangeOrderItemsAction.rejected, (state, action:any) => {
        state.projectChangeOrderItems = [];
        state.error = action?.payload?.data?.message || "Failed to fetch change orders items.";
      })

      // -------- create Change Order -------
      .addCase(fmProjectsAction.createChangeOrderAction.pending, (state, action) => {
        state.error = "";
        state.createChangeOrder = null;
      })
      .addCase(fmProjectsAction.createChangeOrderAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.createChangeOrder= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to create change order.";
        }
      })
      .addCase(fmProjectsAction.createChangeOrderAction.rejected, (state, action:any) => {
        state.createChangeOrder = null;
        state.error = action?.payload?.data?.message || "Failed to create change order.";
      })

      // -------- save Change Order -------
      .addCase(fmProjectsAction.saveChangeOrderAction.pending, (state, action) => {
        state.error = "";
        // state.createChangeOrder = null;
      })
      .addCase(fmProjectsAction.saveChangeOrderAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          // state.createChangeOrder= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to save change order.";
        }
      })
      .addCase(fmProjectsAction.saveChangeOrderAction.rejected, (state, action:any) => {
        // state.createChangeOrder = null;
        state.error = action?.payload?.data?.message || "Failed to save change order.";
      })

      // -------- get Change Order Details -------
      .addCase(fmProjectsAction.getChangeOrderDetailsAction.pending, (state, action) => {
        state.error = "";
        state.changeOrderDetails = null;
      })
      .addCase(fmProjectsAction.getChangeOrderDetailsAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.changeOrderDetails= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to get change order details.";
        }
      })
      .addCase(fmProjectsAction.getChangeOrderDetailsAction.rejected, (state, action:any) => {
        state.changeOrderDetails = null;
        state.error = action?.payload?.data?.message || "Failed to get change order details.";
      })

      // -------- get Change Order terms -------
      .addCase(fmProjectsAction.getChangeOrderTermsAction.pending, (state, action) => {
        state.error = "";
        state.changeOrderTerms = null;
      })
      .addCase(fmProjectsAction.getChangeOrderTermsAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.changeOrderTerms= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to get change order terms.";
        }
      })
      .addCase(fmProjectsAction.getChangeOrderTermsAction.rejected, (state, action:any) => {
        state.changeOrderTerms = null;
        state.error = action?.payload?.data?.message || "Failed to get change order terms.";
      })

      // -------- save Change Order notes -------
      .addCase(fmProjectsAction.saveChangeOrderNotesAction.pending, (state, action) => {
        state.error = "";
        state.savedChangeOrderNotes = null;
      })
      .addCase(fmProjectsAction.saveChangeOrderNotesAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.savedChangeOrderNotes= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to save change order notes.";
        }
      })
      .addCase(fmProjectsAction.saveChangeOrderNotesAction.rejected, (state, action:any) => {
        state.savedChangeOrderNotes = null;
        state.error = action?.payload?.data?.message || "Failed to save change order notes.";
      })

      // -------- save Change Order Intro -------
      .addCase(fmProjectsAction.saveChangeOrderIntroAction.pending, (state, action) => {
        state.error = "";
        // state.changeOrderTerms = null;
      })
      .addCase(fmProjectsAction.saveChangeOrderIntroAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          // state.changeOrderTerms= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to save change order intro.";
        }
      })
      .addCase(fmProjectsAction.saveChangeOrderIntroAction.rejected, (state, action:any) => {
        // state.changeOrderTerms = null;
        state.error = action?.payload?.data?.message || "Failed to save change order intro.";
      })

      // -------- upload Change Order pdf -------
      .addCase(fmProjectsAction.uploadChangeOrderPdfAction.pending, (state, action) => {
        state.changeOrderUploadSuccess = false;
        state.error = "";
      })
      .addCase(fmProjectsAction.uploadChangeOrderPdfAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.changeOrderUploadSuccess = true;
          state.error = "";
        } else {
          state.changeOrderUploadSuccess = false;
          state.error = "Failed to upload change order pdf.";
        }
      })
      .addCase(fmProjectsAction.uploadChangeOrderPdfAction.rejected, (state, action:any) => {
        state.changeOrderUploadSuccess = false;
        state.error = action?.payload?.data?.message || "Failed to upload change order pdf.";
      })

      // -------- get Expenses items -------
      .addCase(fmProjectsAction.getProjectExpensesListAction.pending, (state, action) => {
        state.error = "";
        state.projectExpensesList = [];
      })
      .addCase(fmProjectsAction.getProjectExpensesListAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.projectExpensesList= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to fetch expenses items.";
        }
      })
      .addCase(fmProjectsAction.getProjectExpensesListAction.rejected, (state, action:any) => {
        state.projectExpensesList = [];
        state.error = action?.payload?.data?.message || "Failed to fetch expenses items.";
      })

      // -------- add estimated Expense -------
      .addCase(fmProjectsAction.addProjectEstimatedExpenseAction.pending, (state, action) => {
        state.error = "";
        // state.projectExpensesList = [];
      })
      .addCase(fmProjectsAction.addProjectEstimatedExpenseAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.projectExpensesList= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to add new estimated expense.";
        }
      })
      .addCase(fmProjectsAction.addProjectEstimatedExpenseAction.rejected, (state, action:any) => {
        // state.projectExpensesList = [];
        state.error = action?.payload?.data?.message || "Failed to add new estimated expense.";
      })

      // -------- update estimated Expense -------
      .addCase(fmProjectsAction.updateProjectEstimatedExpenseAction.pending, (state, action) => {
        state.error = "";
      })
      .addCase(fmProjectsAction.updateProjectEstimatedExpenseAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.error = "";
        } else {
          state.error = "Failed to update estimated expense.";
        }
      })
      .addCase(fmProjectsAction.updateProjectEstimatedExpenseAction.rejected, (state, action:any) => {
        state.error = action?.payload?.data?.message || "Failed to update estimated expense.";
      })

      // -------- upload expense -------
      .addCase(fmProjectsAction.uploadExpenseAction.pending, (state, action) => {
        const formData = action?.meta?.arg.formData;
        const file = formData.get('file');
        if(file){
          state.expenseUploadingFile = file;
        }
        state.error = "";
        state.expenseDetails = null;
        state.expenseUploadLoader = true;
      })
      .addCase(fmProjectsAction.uploadExpenseAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.error = "";
          state.expenseDetails = action?.payload?.data?.data;
        } else {
          state.error = "Failed to upload expense.";
        }
        state.expenseUploadLoader = false;
      })
      .addCase(fmProjectsAction.uploadExpenseAction.rejected, (state, action:any) => {
        state.error = action?.payload?.data?.message || "Failed to upload expense.";
        state.expenseUploadLoader = false;
      })

      // -------- upload attachment for expense -------
      .addCase(fmProjectsAction.uploadExpenseAttachmentAction.pending, (state, action) => {
        state.error = "";
        state.expenseDetails = null;
        // state.expenseUploadLoader = true;
      })
      .addCase(fmProjectsAction.uploadExpenseAttachmentAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.error = "";
          // merging updated data from the form
          let savedExpenseDetail = {...action?.payload?.data?.data}
          savedExpenseDetail = {
            ...savedExpenseDetail,
            ...action?.meta?.arg?.updatedExpense
          }
          
          state.expenseDetails = {
            expenseDetail: savedExpenseDetail
          }
        } else {
          state.error = "Failed to upload expense.";
        }
        // state.expenseUploadLoader = false;
      })
      .addCase(fmProjectsAction.uploadExpenseAttachmentAction.rejected, (state, action:any) => {
        state.error = action?.payload?.data?.message || "Failed to upload expense.";
        // state.expenseUploadLoader = false;
      })

      // -------- get expense details -------
      .addCase(fmProjectsAction.getExpenseDetailsAction.pending, (state, action) => {
        state.error = "";
        state.expenseDetails = null;
      })
      .addCase(fmProjectsAction.getExpenseDetailsAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.error = "";
          // state.expenseDetails = action?.payload?.data?.data;
          state.expenseDetails = {
            expenseDetail: action?.payload?.data?.data
          }
        } else {
          state.error = "Failed to fetch expense details.";
        }
      })
      .addCase(fmProjectsAction.getExpenseDetailsAction.rejected, (state, action:any) => {
        state.error = action?.payload?.data?.message || "Failed to fetch expense details.";
      })

      // -------- get unverified expense listing -------
      .addCase(fmProjectsAction.getUnverifiedExpensesListAction.pending, (state, action) => {
        state.error = "";
        state.unverifiedExpenses = [];
      })
      .addCase(fmProjectsAction.getUnverifiedExpensesListAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.error = "";
          state.unverifiedExpenses = action?.payload?.data?.data;
        } else {
          state.error = "Failed to fetch unverified expenses.";
        }
      })
      .addCase(fmProjectsAction.getUnverifiedExpensesListAction.rejected, (state, action:any) => {
        state.error = "Failed to fetch unverified expenses.";
      })

      // -------- compare unverified expense -------
      .addCase(fmProjectsAction.compareUnverifiedExpenseAction.pending, (state, action) => {
        state.error = "";
        state.expenseDetails = null;
      })
      .addCase(fmProjectsAction.compareUnverifiedExpenseAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.error = "";
          // state.expenseDetails = action?.payload?.data?.data;
          state.expenseDetails = {
            analysedDetails: action?.payload?.data?.data?.deprecatedExpense,
            expenseDetail: action?.payload?.data?.data?.fetchedExpense,
            deprecatedExpenseId: action?.payload?.data?.data?.deprecatedExpenseId
          }
        } else {
          state.error = "Failed to upload expense.";
        }
      })
      .addCase(fmProjectsAction.compareUnverifiedExpenseAction.rejected, (state, action:any) => {
        state.error = action?.payload?.data?.message || "Failed to upload expense.";
      })

      // -------- get uploaded document list -------
      .addCase(fmProjectsAction.changeOrderAttachmentListAction.pending, (state, action) => {
        state.error = "";
        state.changeOrderAttachmentList = null;
      })
      .addCase(fmProjectsAction.changeOrderAttachmentListAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.changeOrderAttachmentList= action?.payload?.data?.data;
          state.error = "";
        } else {
          state.error = "Failed to get uploaded document list.";
        }
      })
      .addCase(fmProjectsAction.changeOrderAttachmentListAction.rejected, (state, action:any) => {
        state.changeOrderAttachmentList = null;
        state.error = action?.payload?.data?.message || "Failed to get uploaded document list.";
      })

      // -------- remove uploaded attachment -------
      .addCase(fmProjectsAction.removeChangeOrderAttachmentAction.pending, (state, action) => {
        state.changeOrderRemoveSuccess = false;
        state.error = "";
      })
      .addCase(fmProjectsAction.removeChangeOrderAttachmentAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.changeOrderRemoveSuccess = true;
          state.error = "";
        } else {
          state.changeOrderRemoveSuccess = false;
          state.error = "Failed to remove uploaded attachment.";
        }
      })
      .addCase(fmProjectsAction.removeChangeOrderAttachmentAction.rejected, (state, action:any) => {
        state.changeOrderRemoveSuccess = false;
        state.error = action?.payload?.data?.message || "Failed to remove uploaded attachment.";
      })

      // -------- export change order -------
      .addCase(fmProjectsAction.exportChangeOrderAction.pending, (state, action) => {
        state.changeOrderRelease = false;
        state.error = "";
      })
      .addCase(fmProjectsAction.exportChangeOrderAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.changeOrderRelease = true;
          state.error = "";
        } else {
          state.changeOrderRelease = false;
          state.error = "Failed to export change order.";
        }
      })
      .addCase(fmProjectsAction.exportChangeOrderAction.rejected, (state, action:any) => {
        state.changeOrderRelease = false;
        state.error = action?.payload?.data?.message || "Failed to export change order.";
      })

      // -------- delete change order -------
      .addCase(fmProjectsAction.deleteChangeOrderAction.pending, (state, action) => {
        state.changeOrderDelete = null;
        state.error = "";
      })
      .addCase(fmProjectsAction.deleteChangeOrderAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.changeOrderDelete = action?.payload?.data?.data;
          state.error = "";
        } else {
          state.changeOrderDelete = null;
          state.error = "Failed to delete change order.";
        }
      })
      .addCase(fmProjectsAction.deleteChangeOrderAction.rejected, (state, action:any) => {
        state.changeOrderDelete = null;
        state.error = action?.payload?.data?.message || "Failed to delete change order.";
      })

      // -------- duplicate change order -------
      .addCase(fmProjectsAction.duplicateChangeOrderAction.pending, (state, action) => {
        state.changeOrderDuplicate = null;
        state.error = "";
      })
      .addCase(fmProjectsAction.duplicateChangeOrderAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status===200) {
          state.changeOrderDuplicate = action?.payload?.data?.data;
          state.error = "";
        } else {
          state.changeOrderDuplicate = null;
          state.error = "Failed to create duplicate change order.";
        }
      })
      .addCase(fmProjectsAction.duplicateChangeOrderAction.rejected, (state, action:any) => {
        state.changeOrderDuplicate = null;
        state.error = action?.payload?.data?.message || "Failed to create duplicate change order.";
      })

      // get payment schedule
      .addCase(fmProjectsAction.getCOPaymentScheduleAction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
        state.coPaymentSchedule = [];
      })
      .addCase(fmProjectsAction.getCOPaymentScheduleAction.fulfilled, (state, action) => {
        state.loading = false;
        if(action?.payload?.data?.status===200){
          state.coPaymentSchedule = action.payload.data.data;
          state.success = action.payload.data.data;
        }else{
          state.success = null;
          state.coPaymentSchedule = [];
          state.error = action?.payload?.data ?? 'Get Payment Failed';
        }
      })
      .addCase(fmProjectsAction.getCOPaymentScheduleAction.rejected, (state, action:any) => {
        state.loading = false;
        state.success = null;
        state.coPaymentSchedule = [];
        state.error = action?.payload?.data ?? 'Get Payment Failed';
      })

      // -------- rfp list -------
      .addCase(fmProjectsAction.rfpListAction.pending, (state, action) => {
        state.rfpList = null;
        state.error = "";
      })
      .addCase(fmProjectsAction.rfpListAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 200) {
          state.rfpList = action?.payload?.data?.data;
          state.error = "";
        } else {
          state.rfpList = null;
          state.error = "Failed to fetch rfp list.";
        }
      })
      .addCase(fmProjectsAction.rfpListAction.rejected, (state, action: any) => {
        state.rfpList = null;
        state.error = action?.payload?.data?.message || "Failed to fetch rfp list.";
      })

      // -------- create rfp -------
      .addCase(fmProjectsAction.createRfpAction.pending, (state, action) => {
        // state.rfpList = null;
        state.error = "";
      })
      .addCase(fmProjectsAction.createRfpAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 200) {
          // state.rfpList = action?.payload?.data?.data;
          state.error = "";
        } else {
          // state.rfpList = null;
          state.error = "Failed to create rfp.";
        }
      })
      .addCase(fmProjectsAction.createRfpAction.rejected, (state, action: any) => {
        // state.rfpList = null;
        state.error = action?.payload?.data?.message || "Failed to create rfp.";
      })

      // -------- update rfp -------
      .addCase(fmProjectsAction.updateRfpAction.pending, (state, action) => {
        // state.rfpList = null;
        state.error = "";
      })
      .addCase(fmProjectsAction.updateRfpAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 200) {
          // state.rfpList = action?.payload?.data?.data;
          state.error = "";
        } else {
          // state.rfpList = null;
          state.error = "Failed to update rfp.";
        }
      })
      .addCase(fmProjectsAction.updateRfpAction.rejected, (state, action: any) => {
        // state.rfpList = null;
        state.error = action?.payload?.data?.message || "Failed to update rfp.";
      })

      // -------- unrelease change order -------
      .addCase(fmProjectsAction.unreleaseChangeOrderAction.pending, (state, action) => {
        state.error = "";
      })
      .addCase(fmProjectsAction.unreleaseChangeOrderAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 200) {
          state.error = "";
        } else {
          state.error = "Failed to unrelease change order.";
        }
      })
      .addCase(fmProjectsAction.unreleaseChangeOrderAction.rejected, (state, action: any) => {
        state.error = action?.payload?.data?.message || "Failed to unrelease change order.";
      })

      // -------- rearrange overview list -------
      .addCase(fmProjectsAction.rearrangeOverviewListAction.pending, (state, action) => {
        state.error = "";
      })
      .addCase(fmProjectsAction.rearrangeOverviewListAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 200) {
          state.error = "";
        } else {
          state.error = "Failed to rearrange overview list.";
        }
      })
      .addCase(fmProjectsAction.rearrangeOverviewListAction.rejected, (state, action: any) => {
        state.error = action?.payload?.data?.message || "Failed to rearrange overview list.";
      })

      // -------- generate zip -------
      .addCase(fmProjectsAction.generateZipAction.pending, (state, action) => {
        state.error = "";
      })
      .addCase(fmProjectsAction.generateZipAction.fulfilled, (state, action) => {
        if (action?.payload?.data?.status === 200) {
          state.error = "";
        } else {
          state.error = "Failed to generate zip.";
        }
      })
      .addCase(fmProjectsAction.generateZipAction.rejected, (state, action: any) => {
        state.error = action?.payload?.data?.message || "Failed to generate zip.";
      })

  },
});

export const { cleanChangeOrder, cleanProjectDetails } = fmProjectsSlice.actions;
export default fmProjectsSlice.reducer;